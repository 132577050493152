import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import './../../assets/scss/typography.scss'
import { number_to_price } from 'helper/common'
import PopupMessage from 'components/PopupMessageAlert'
import { CUSTOMER_RECEIPT_STATUS_TO_TEXT } from 'constants/global'
import { ORDER_PAYMENT_STATUS_COLOR } from 'constants/payment'
import { RenderIf } from 'helper/renderIf'

const TYPE_OF_INSURANCE = "Bảo hiểm TNDS"

function OrderHistoryView({ element }) {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(undefined)
  const { t: translation } = useTranslation()

  const redirectOrderDetail = () => history.push(`/order-detail/${element?.orderId}`)

  const orderStatus = CUSTOMER_RECEIPT_STATUS_TO_TEXT[element?.paymentStatus?.toUpperCase()] || ''
  const color = ORDER_PAYMENT_STATUS_COLOR.find(item => item.value === element?.paymentStatus)?.color

  const checkTypeOfOrder = (products) => {
    const productNamesArray = products?.map((product) => product.productName)
    const productNames = productNamesArray.filter((name, index) => productNamesArray.indexOf(name) === index)
    const orderItemVehicle = products[0]?.orderItemVehicle
    const vehicleQuantity = products[0]?.quantity

    let vehicleType

    if (orderItemVehicle !== undefined) {
      vehicleType = JSON.parse(orderItemVehicle)?.vehicleType
    }

    if (productNames.length !== 0) {
      if (vehicleType === undefined) {
        if (vehicleQuantity === 1) {
          return `Gói thông báo phạt nguội ${productNames.map((name) => name)}`
        } else {
          return `Gói thông báo phạt nguội ${productNames.map((name) => name)} cho ${vehicleQuantity} xe`
        }
      } else if (vehicleType === 30) return `${TYPE_OF_INSURANCE} Xe máy ${productNames.map((name) => name)}`
      else return `${TYPE_OF_INSURANCE} Ôtô ${productNames.map((name) => name)}`
    } else return '...'
  }

  return (
    <div className="box-order-history__content" onClick={redirectOrderDetail}>
      <div className="box-order-history--item">
        <div className='box-order-history--content'>
          <div className='box-order-history-info'>
            <div className='box-order-history-more'>
              <div className="">
                <div className="d-flex align-items-center mt-1">
                  <div className='box-order-history-more--icon'>Đơn hàng:</div>
                  <div className="d-inline box-order-history-text text-small orderName">{checkTypeOfOrder(element?.orderItems)}</div>
                </div>
                <div className="d-flex align-items-center mt-1">
                  <div className='box-order-history-more--icon'>
                    {translation('order-id')}:
                  </div>
                  <div className="d-inline box-order-history-text text-small orderCode">{element?.orderCode || '...'}</div>
                </div>
                <div className="d-flex align-items-center mt-1">
                  <div className='box-order-history-more--icon'>
                    {translation('order-totalPay')}:
                  </div>
                  <div className="d-inline box-order-history-text text-small totalAmount">{number_to_price(element?.totalAmount) || '...'}</div>
                </div>
                <div className="d-flex align-items-center mt-1">
                  <div className='box-order-history-more--icon'>
                    {translation('timeOrder')}:
                  </div>
                  <div className="d-inline box-order-history-text timeOrder text-small">
                    {element?.createdAt ? moment(element?.createdAt).format('DD/MM/YYYY hh:mm') : '--/--/--'}
                  </div>
                </div>
                <div className='d-flex align-items-center mt-1'>
                  <div className='box-order-history-more--icon'>
                    {translation('order-payment-status')}:
                  </div>
                  <div className='d-inline box-order-history-text text-small orderStatus' style={{ fontWeight: 'bold', color: color }}>{orderStatus}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenderIf isTrue={errorMessage}>
        <PopupMessage isModalOpen={errorMessage} onClose={() => { setErrorMessage(undefined) }} text={errorMessage} ></PopupMessage>
      </RenderIf>
    </div>

  )
}

export default OrderHistoryView
