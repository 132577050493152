import React from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './TheFooter.scss'
import { routes } from 'App';
import { STATIONS_TYPE } from 'constants/stationsList';
import companyInfo from 'constants/companyInfo'
import addKeyLocalStorage from 'helper/localStorage';
import Facebook from "../../assets/new-icons/facebook.png";
import Tiktok from "../../assets/new-icons/tiktok.png";
import Youtube from "../../assets/new-icons/youtube.png";
import Zalo from "../../assets/new-icons/zalo.png";
import VisaIcon from "../../assets/payment/VISA.svg";
import MasterCardIcon from "../../assets/payment/MASTERCARD.svg";
import JCBIcon from "../../assets/payment/JCB.svg";
import AMEXIcon from "../../assets/payment/AMEX.svg";
import VNPAYIcon from "../../assets/payment/VNPAY.svg";
import ZALOPAYIcon from "../../assets/payment/ZALOPAY.svg";
import NAPASIcon from "../../assets/payment/NAPAS.svg";
import KREDIVOIcon from "../../assets/payment/KREDIVO.svg";
import MOMOIcon from "../../assets/payment/MOMO.svg";
import FOXPAYIcon from "../../assets/payment/FOXPAY.svg";
import ALEPAYIcon from "../../assets/payment/ALEPAY.svg";
import MUADEEIcon from "../../assets/payment/MUADEE.svg";
import HOMEPAYIcon from '../../assets/payment/HOMEPAY.svg'
import APPLEPAYIcon from '../../assets/payment/APPLEPAY.svg'
import SAMSUNGPAYIcon from '../../assets/payment/SAMSUNGPAY.svg'
import GOOGLEPAYIcon from '../../assets/payment/GOOGLEPAY.svg'
import QRAppStore from "../../assets/new-icons/QRApp.jpg";
import BAppStore from "../../assets/new-icons/BApp.png";
import BCHPlay from "../../assets/new-icons/BCHPlay.png";
import QRCHPlay from "../../assets/new-icons/QRPlay.jpg";

export const TheFooter = (props) => {
  const { t: translation } = useTranslation()
  let userData = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))

  const LIST_POLICY = [
    {
      label: translation('footer.shipment-policy'),
      href: '/shipment-policy'
    },
    {
      label: translation('footer.refund-policy'),
      href: '/refund-policy'
    },
    {
      label: translation('footer.privacy-policy'),
      href: '/privacy-policy'
    },
  ]

  const LIST_ABOUT_US = [
    {
      label: translation('footer.activities-statute'),
      href: '/general-policy'
    },
    {
      label: translation('footer.contact-cooperation'),
      href: '/contact-cooperation'
    },
    {
      label: translation('footer.common-question'),
      href: '/instruction'
    },
    {
      label: translation('footer.payment-method'),
      href: '/payment-method'
    },
    {
      label: translation('footer.complaint-handling'),
      href: '/complaint-handling'
    },
  ]

  const LIST_CONTACT = [
    {
      header: translation('footer.registration-booking'),
      label: '1800.6601'
    },
    {
      header: translation('footer.complaint-feedback'),
      label: '0343902960'
    },
    {
      header: translation('footer.technical-support'),
      label: '0343902960'
    }
  ]
  const LIST_SOCIAL_NETWORK = [
    {
      icon: Facebook,
      href: 'https://www.facebook.com/groups/940007330455923'
    },
    {
      icon: Zalo,
      href: 'https://zalo.me/ttdk2023'
    },
    {
      icon: Tiktok,
      href: 'https://www.tiktok.com/@ttdkdatlichdangkiem?_t=8gGKdPGglGM&_r=1'
    },
    {
      icon: Youtube,
      href: 'https://www.youtube.com/@TTDK-DatLichDangKiem/about'
    },
  ]

  const LIST_SERVICE_BTNS = [
    {
      label: 'Trung tâm đăng kiểm',
      link: `/stations?type=${STATIONS_TYPE.CENTER}&name=Trung tâm đăng kiểm`,
    },
    {
      label: translation('Rescue'),
      link: `/stations?type=${STATIONS_TYPE.INSPECTION_RESCUE}&name=cuuho`,
    },
    {
      label: translation('footer.user-search.insurance'),
      link: `https://ttdk-sanbox-muabaohiem.service.makefamousapp.com?appUserId=${userData?.appUserId}&token=${userData?.token}`,
    },
    {
      label: translation('Transport-cooperatives'),
      link: `/stations?type=${STATIONS_TYPE.TRANSPORT_COOPERATIVE}&name=htx`,
    },
    {
      label: translation('Buy-sell-cars'),
      link: `/stations?type=${STATIONS_TYPE.USED_CAR_TRADING}&name=muabanxe`,
    },
    {
      label: translation('footer.user-search.insurance-unit'),
      link: `/stations?type=${STATIONS_TYPE.INSURANCE_COMPANY}&name=DonviBH`,
    },
    {
      label: 'Mua bán phụ tùng ô tô',
      link: `/stations?type=${STATIONS_TYPE.SPARE_PARTS_DEALERSHIP}&name=Mua bán phụ tùng ô tô`,
    },
    {
      label: 'Bảo dưỡng <br> ô tô',
      link: `/stations?type=${STATIONS_TYPE.GARAGE}&name=Bảo dưỡng ô tô`,
    },
    {
      label: 'Bãi giữ xe',
      link: `/stations?type=${STATIONS_TYPE.PARKING_LOT}&name=Bãi giữ xe`,
    },
    {
      label: translation('Vehicle-renovation'),
      link: `/stations?type=${STATIONS_TYPE.VEHICLE_RENOVATION_COMPANY}&name=caitao`,
    },
    {
      label: 'Trường học lái xe',
      link: `/stations?type=${STATIONS_TYPE.DRIVING_SCHOOL}&name=Trường học lái xe`,
    },
    {
      label: 'Dịch vụ lái xe hộ',
      link: `/stations?type=${STATIONS_TYPE.CHAUFFEUR_SERVICE}&name=Dịch vụ lái xe hộ`,
    },
    {
      label: 'Tư vấn SX phụ tùng xe',
      link: `/stations?type=${STATIONS_TYPE.PARTS_MANUFACTURING_CONSULTANCY}&name=Tư vấn SX phụ tùng xe`,
    },
    {
      label: 'Khám sức khỏe lái xe',
      link: `/stations?type=${STATIONS_TYPE.DRIVER_HEALTH}&name=Khám sức khỏe lái xe`,
    },

    {
      label: 'Cộng đồng Review',
      link: 'https://www.facebook.com/groups/940007330455923/?ref=share&mibextid=K35XfP',
    },
    {
      label: translation('BHTNDS'),
      link: '/check-vihcle?service=/gia-han-bao-hiem-tnds&redirect=0',
    },
    {
      label: translation('material-insurance'),
      link: `/check-vihcle?service=https://ttdk.partner.saladin.vn/bao-hiem-vat-chat-toan-dien-xe-oto&redirect=1&bhvc=1`,
    },
    {
      label: translation('Buy-sell-cars'),
      link: `https://vucar.vn/ban-xe?utm_source=TTDK&utm_medium=Landing%20Page&utm_campaign=C2B_TTDK`,
    },
    {
      label: translation('customer-support'),
      link: 'https://www.facebook.com/groups/262350473627015',
    },
    {
      label: translation('shares-experiences'),
      link: 'https://www.facebook.com/groups/316778718119573/',
    },
    {
      label: translation('Vehicle-valuation'),
      link: 'https://dinhgiaxe.ai.vn/',
    },
    {
      label: 'Đăng ký dán thẻ ePass',
      link: "https://partner.ttdk.com.vn/booking?apikey=abeec15b-092e-424e-b3ec-9166a33a1539&vntId=H%E1%BB%93%20Ch%C3%AD%20Minh&scheduleType=2",
    },
    {
      label: translation('schedule-maintenance'),
      link: "https://oga.datxe.com/dlbd/?apiKey=a7f4c2eb-057b-4596-b016-b386b49af723",
    },
    {
      label: 'Nộp hồ sơ xe mới',
      link: '/kiemtraphatnguoi',
    },
    {
      label: 'Đăng kiểm xe định kỳ',
      link: routes.booking.path,
    },
    {
      label: 'Thay đổi thông tin xe',
      link: routes.booking.path,
    },
    {
      label: 'Tư vấn bảo hiểm',
      link: routes.booking.path,
    },
    {
      label: 'Tư vấn bảo dưỡng',
      link: routes.booking.path,
    },
    {
      label: 'Tư vấn hoán cải',
      link: routes.booking.path,
    },
    {
      label: translation('footer.user-search.criminal-consult'),
      link: routes.booking.path,
    },
    {
      label: 'Gia hạn đăng kiểm',
      link: `https://giahanxcg.vr.org.vn/#/dashboard`,
    },
    {
      label: 'Gia hạn BH TNDS',
      link: `/gia-han-bao-hiem-tnds?title=ghbh`,
    },
    {
      label: translation('footer.user-search.vehicleInfo-consult'),
      link: `${process.env.REACT_APP_DEPLOY_URL}/kiemtraphatnguoi`,
    },
  ]

  const LIST_PAYMENT_COMPANY = [VisaIcon, MasterCardIcon, JCBIcon, AMEXIcon, VNPAYIcon, ZALOPAYIcon, NAPASIcon, KREDIVOIcon, MOMOIcon, FOXPAYIcon, ALEPAYIcon, MUADEEIcon, HOMEPAYIcon, APPLEPAYIcon, SAMSUNGPAYIcon, GOOGLEPAYIcon]

  const mapperListIncludeLink = (item) => {
    return (
      <>
        <span style={{ color: '#ccc' }}>{item.header}</span>
        <li key={item.label}>
          <Link to={item.href} className='d-flex flex-column'>
            <span>{item.label}</span>
          </Link>
        </li>
      </>
    )
  }

  const LIST_FOOTER_METADATA = [
    [
      {
        title: translation('footer.connect-us'),
        list: (
          <>
            <h3 className='widget-item-title mb-2'>{translation('footer.support-switchboard')}</h3>
            {LIST_CONTACT.map(mapperListIncludeLink)}
          </>
        ),
        social: (
          <div className='' style={{ display: '-webkit-inline-box', gap: '15px', margin: '10px 0 24px' }}>
            {LIST_SOCIAL_NETWORK.map(item => (
              <p className='d-flex' key={item.href}>
                <a target='_blank' rel="noopener noreferrer" href={item.href}>
                  <img style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={item.icon} alt="Social icon" />
                </a>
              </p>
            ))}
          </div>)
      }
    ],
    [
      {
        title: translation('footer.about-us'),
        list: LIST_ABOUT_US.map(mapperListIncludeLink),
      }
    ],
    [
      {
        title: translation('footer.policy'),
        list: LIST_POLICY.map(mapperListIncludeLink)
      }
    ],

    [
      {
        title: translation('footer.payment-support'),
        social: LIST_PAYMENT_COMPANY.map((payment) => (
          <img style={{ marginBottom: 6 }} width="60px" height="30px" src={payment} />
        ))
      }
    ],
  ]
  const LIST_INFO_COMPANY = [
    {
      title: 'CHPlay',
      QR: QRCHPlay,
      button: BCHPlay
    },
    {
      title: 'AppStore',
      QR: QRAppStore,
      button: BAppStore
    }
  ]

  const handleChPlay = () => window.open('https://play.google.com/store/apps/details?id=com.ttdk', '_blank')
  const handleAppStore = () => window.open('https://apps.apple.com/vn/app/ttdk-%C4%91%E1%BA%B7t-l%E1%BB%8Bch-%C4%91%C4%83ng-ki%E1%BB%83m/id1663487095?l=vi', '_blank')

  const handleClickOnBtn = (typeOfStore) => typeOfStore === "CHPlay" ? handleChPlay() : handleAppStore()

  return (
    <>
      <div className="footer-mobile" />
      <div className="footer">
        <div className="footer">
          <div className="secarea">
            <div className="container">
              {LIST_FOOTER_METADATA.map((_column, i) => {
                return (
                  <div className="one_fourth" key={i}>
                    <div className="widget-area">
                      {_column.map((_col) => {
                        return (
                          <aside className="widget" key={_col.title}>
                            <h3 className="widget-title">
                              <span>{_col.title}</span>
                            </h3>
                            <div className="execphpwidget">
                              <div className="store">{_col?.store}</div>
                              <div className="social">{_col?.social}</div>
                              <ul className="foolist">{_col.list}</ul>
                            </div>
                          </aside>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className='container'>
            {LIST_INFO_COMPANY.map((_item) => (
              <div key={_item.QR} className={`d-flex flex-column ${_item.QR === QRAppStore ? 'mt-12px' : ''}`}>
                <div className='mb-2'>
                  <img src={_item.QR} width="145px" height="145px" className={_item.QR === QRAppStore ? "QRApp" : "QRPlay"} alt="QR Code" />
                </div>
                <div className='cursor-pointer' onClick={() => handleClickOnBtn(_item.title)}>
                  <img src={_item.button} width="170px" height={_item.button === BAppStore ? "94px" : "70px"} style={{ marginLeft: -12 }} alt="App Button" />
                </div>
              </div>))}
          </div>
          <div className='container mb-3'>
            <div style={{ textAlign: 'center' }}>
              <p>{translation('footer.copyright.other-users-search')}:</p>
              <div className='d-inline'>
                {LIST_SERVICE_BTNS.map((_item) => (
                  <>
                    <a href={_item.link}>
                      <span>{_item.label.split('<br>').join('')}</span>
                    </a>
                    <span className='vertical-rule'>|</span>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className='container d-flex flex-column'>
            <p className="d-inline" style={{ textAlign: 'center' }}>
              © {companyInfo.name} • {translation('footer.copyright.address')}: {companyInfo.address} • {translation('company', { taxId: companyInfo.taxId, time: companyInfo.lastUpdatedAt })}
            </p>
            <p className='d-inline' style={{ textAlign: 'center' }}>
              {translation('footer.copyright.phone-number')}: {companyInfo.phoneNumber} • Email: {companyInfo.email} • {translation('footer.copyright.content-responsibility')}: {companyInfo.director}
            </p>
          </div>
        </div>
      </div >
    </>
  )
}
